import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import AnimatedNumber from "animated-number-vue";

Vue.config.productionTip = false
Vue.use(AnimatedNumber);

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
