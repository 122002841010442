<template>
  <div style="background: #2A2A2A;">
    <div align="center" class="pa-2">
      <div class="grey--text font-weight-light" style="font-size:13px;">©{{ new Date().getFullYear() }} Humantech. All right reserved.</div>          
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({    
      dialog : false,            
    }),
  }
</script>


<style>
.box_circle2{
  background : white;
  border-radius: 1000px;
  box-shadow: 0px 0px 9px 0px #a5a3a3 ;
}
.header_margin {    
  width:100%;
  height:50px;  
}
/* .header_text {    
  background: #000000;   
} */
.content_get {    
  width:100%;
 
  background: #FFFFFF;   
}
.content_why {    
  width:100%;  
  background: #2A2A2A;   
}


#line-height {
  line-height:200%;
}

#item-box {
  width:300px;
  line-height:200%;
}

#item-box2 {
  width:200px;
  line-height:200%;
}
#item-box3 {
  width:400px;
  line-height:200%;
}

 
</style>