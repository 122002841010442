<template>
  <v-app>
    <div class="main_header">      
      <div style="float:left; padding-top: 7px; padding-left: 9px;">
        <v-img                    
          contain
          src="./assets/logo_wide.svg"
          transition="scale-transition"
          width="100px"      
          @click="$vuetify.goTo(0, null)"          
        /> 
      </div>             
      
      <div v-show="wide_windows" style="float:right">
        <v-btn text @click="$vuetify.goTo('#scoll_About', null)" ><span class="mr-1 ">ABOUT</span> </v-btn>
        <v-btn text @click="$vuetify.goTo('#scoll_History', null)"> <span class="mr-1 ">HISTORY</span> </v-btn>                
        <v-btn text @click="$vuetify.goTo('#scoll_Service', null)"> <span class="mr-1 ">SERVICE</span> </v-btn>        
        <v-btn text @click="$vuetify.goTo('#scoll_Cert', null)"> <span class="mr-1 ">CERT</span> </v-btn>        
        <v-btn text @click="$vuetify.goTo('#scoll_Contact', null)"> <span class="mr-1 ">CONTACT</span> </v-btn>                
      </div>

      <div v-show="!wide_windows">        
      </div>
    </div>
    <div>
      <v-row v-resize="onResize">      
      </v-row>
    </div>
    <div >            
      <v-carousel id="blinking" hide-delimiters continuous :cycle="true">
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>        
      <!-- <v-carousel class="blinking" hide-delimiters continuous :cycle="true">
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>         -->
    </div>    
    
    <Content/>
    

  
    <Conditions/>
     
  </v-app>
</template>

<script>
import Content from './components/Content';
import Conditions from './components/Conditions';

export default {
  name: 'App',
  components: {
    Content,
    Conditions,
  },

  mounted () {
      this.onResize()
  },
  data: () => ({
    dialog : false,
    dialog2 : false,
    windowSize: {
        x: 0,
        y: 0,
      },
    wide_windows : true,
    ratio : 1,
    items: [
          {
            src: require('./assets/background1.png'),
          },
          {
            src: require('./assets/background2.png'),
          },
          {
            src: require('./assets/background3.png'),
          },
          {
            src: require('./assets/background4.png'),
          },
        ],
  }),

  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.ratio = window.innerWidth / window.innerHeight * 1.2;
      this.wide_windows = (window.innerWidth >= 640);
    },
  },
};
</script>


<style>
  .v-application {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
  }
  
  .main_header {   
  z-index: 100;
  padding: 7px;
  display:inline-block;
  position: fixed;  
  left: 0;
  top: 0;
  width:100%;
  height:50px;
  background: #FFFFFFCC;      
  } 


  #blinking{
    -webkit-animation:blink 10s ease-in-out infinite alternate;
      -moz-animation:blink 10s ease-in-out infinite alternate;
      animation:blink 10s ease-in-out infinite alternate;
  }
  @-webkit-keyframes blink{
      0% {opacity:0.1;}
      100% {opacity:1;}
  }
  @-moz-keyframes blink{
      0% {opacity:0.1;}
      100% {opacity:1;}
  }
  @keyframes blink{
      0% {opacity:0.1;}
      100% {opacity:1;}
  } 
</style>


