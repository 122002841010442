<template>
  <div>
    <div align="center" justify="center" style="position: relative; top:-343px; height: 0px;">      
      <div class="text-center" id="blinking2"  >        
        <img                              
          src="../assets/logo_only.svg"           
          width="300px"                    
        />
      </div>
    </div>
    
    <div style="width: 100%;">      
      <div style="background-color: red; position: relative; max-width:100vw; height: 0px; right:12px; top:-30px; color: #000000cc; font-size: 14px; font-weight: 300; text-align: right;">
      홈페이지에 사용된 이미지는 모두 AI로 생성되었습니다.
      </div>
    </div>
    
    <div align="center" justify="center" >      
      <div class="justify-center" color="white">
        <v-row class="ma-2" style="max-width: 900px;">
          <v-col cols="12" sm="12" md="12">
            <div class="title_header">Creating Value, Through Networks</div>
          </v-col>
          <v-col cols="12" sm="12" md="4"  v-resize="onResize" id="CircleWidth">
            <div class="box_circle2 ma-3 content_center" :style="'height:'+iCircleWidth+'px;'">
              <div>
                <animated-number :value="30"  :duration="1500" :formatValue="formatToPrice" class="grey--text text--darken-4" style="font-size: 90px;"/>                            
                <div style="font-size: 20px;" class="mt-n5">사업기간</div>    
              </div>
            </div>
          </v-col>
          
          <v-col cols="12" sm="12" md="4">
            <div class="box_circle2 ma-3 content_center"  :style="'height:'+iCircleWidth+'px;'">
              <div>                
                <animated-number :value="11348"  :duration="3000" :formatValue="formatToPrice" class="grey--text text--darken-4" style="font-size: 55px;"/>                  
                <div class="d-flex content_center">
                  <div style="font-size: 20px;">시공능력평가액</div>    
                  <div style="position: relative; width : 0px; height: 0px; top:-10px" >
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <div class="box_circle2 ma-3 content_center"  :style="'height:'+iCircleWidth+'px;'">
              <div>
                <animated-number :value="25094"  :duration="3000" :formatValue="formatToPrice" class="grey--text text--darken-4" style="font-size: 55px;"/>                  
                <div class="d-flex content_center">
                  <div style="font-size: 20px;">최근5년 실적합계</div>    
                </div>                  
              </div>
            </div>
            <div style="position: relative; width : 0px; height: 0px; top:20px" >
              <div style="width: 160px; font-size: 14px; color: #000000a0;" >
                (단위 : 년,백만원/ 정보통신)
              </div>                      
            </div>
          </v-col>
        </v-row>
      </div>
    </div>  

    <div id="scoll_About" align="center" justify="center" >      
      <div class="mt-10">
        <div class="title_caption">ABOUT</div>
        <div class="title_header">휴먼테크</div>
        <div class="title_sub">신뢰의 기술로 미래를 여는 시공 전문 기업</div>
      </div>        
      <div class="d-flex justify-center flex-wrap mt-4" color="white" style="display: flex; align-items: center;">
        <div class="ma-3" style="">                            
          <img                             
            class="img_outline"  
            src="../assets/about.png"           
            width="300px"/>                                    
        </div>
        <div class="ma-3" style="width : 400px">                            
          <div class="content_header text-left" style="font-weight: 800;">
핵심역량 집중<br>
품질,기술, 안전 지상<br>
고객가치상승 지향
          </div>
          <div class="content_caption text-left mt-4">
휴먼테크는 정보통신공사와 전기공사를 전문으로 하는 기업입니다. <br><br>
우리는 기술의 진보를 선도하고, 보다 경제적이고 안정적인 서비스를 제공하며, 고객의 만족을 최우선으로 생각합니다.
다년간의 시공경험과 각 분야별 전문가들을 보유하고 있으며 인증 된 품질,환경,안전보건 경영시스템 구축 운영으로 최상의 시공품질과 가치를 창출합니다.    
정보통신공사업분야에서는 통신망 구축 및 유지보수, 시스템 통합(SI), 네트워크 솔루션 등 다양한 서비스를 제공하고 있습니다.
또한, 전기공사업 분야에서는 고품질의 전력설비를 제공하고, 안전하고 신뢰할 수 있는 전력 공급을 위해  끊임없이 노력하고 있습니다.<br><br>
휴먼테크는 항상 성장하고 발전하기 위해 노력하고 있습니다. 고객의 소중한 의견을 우리의 발전의 원동력으로 삼아, 더 나은 서비스를 제공하기 위한 노력을 멈추지 않겠습니다. 앞으로도 휴먼테크가 고객과 함께 동반 성장할 수 있도록 지속적인 관심과 사랑을 부탁드립니다.<br><br>
감사합니다.

          </div>
        </div>
      </div>
      
      <div class="ma-3" style="width: 400px;" >  
        <img             
          src="../assets/3value.png"           
          width="400px"/>
      </div>   
    </div>


    <div class="mt-5" id="scoll_History" align="center" justify="center" >            
      <div style="height: 30px;"></div>
      <div class="mt-10">
        <div class="title_caption">HISTORY</div>                
      </div>        
      <div class="justify-center" color="white">
        <div class="text-left" style="max-width:500px;">
          <v-timeline dense>          
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2023.04.10</v-chip>KT 정보통신공사 파트너 재선정</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2020.11.13</v-chip>ISO 45001 인증 획득</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2017.01.18</v-chip>OHSAS 18001 인증 획득</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2014.03.06</v-chip>직접생산확인 증명서(전산업무) 발급</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2011.10.18</v-chip>ISO 14001 인증 획득</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2008.01.30</v-chip>전기공사업 등록</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2004.12.31</v-chip>자본금 증자 (8억 5천만원)</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2003.02.05</v-chip>휴먼테크㈜로 상호 변경</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2002.12.27</v-chip>KT 시설공사 협력업체(1군) 선정</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >2001.04.03</v-chip>소프트웨어사업자 신고</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >1998.01.01</v-chip>ISO 9001 인증 획득</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >1995.07.20</v-chip>정보통신공사업 1등급 허가 취득</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >1994.03.15</v-chip>선로공사업 1등급 등급승격</div>            
            </v-timeline-item>
            <v-timeline-item color="blue" small>
              <div class="content_caption" style="font-size: 15px;"><v-chip label class="mr-2 mt-n1" small >1994.02.05</v-chip>거룡통신 설립(선로공사업 2등급)</div>
            </v-timeline-item>
          </v-timeline>
        </div>

        <!-- <div class="justify-center" color="white">
          <v-row class="ma-10" style="max-width: 900px;">
            <v-col cols="12" sm="12" md="4"  v-resize="onResize" id="CircleWidth">
              <div class="box_circle2 ma-3 content_center" :style="'height:'+iCircleWidth+'px;'">
                <div>
                  <animated-number :value="30"  :duration="500" :formatValue="formatToPrice" class="grey--text text--darken-4" style="font-size: 100px;"/>                            
                  <div style="font-size: 20px;" class="mt-n5">사업기간</div>    
                </div>
              </div>
            </v-col>
            
            <v-col cols="12" sm="12" md="4">
              <div class="box_circle2 ma-3 content_center"  :style="'height:'+iCircleWidth+'px;'">
                <div>                
                  <animated-number :value="11348"  :duration="1000" :formatValue="formatToPrice" class="grey--text text--darken-4" style="font-size: 70px;"/>                  
                  <div class="d-flex content_center">
                    <div style="font-size: 20px;">시공능력평가액</div>    
                    <div style="position: relative; width : 0px; height: 0px; top:-10px" >
                    </div>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <div class="box_circle2 ma-3 content_center"  :style="'height:'+iCircleWidth+'px;'">
                <div>
                  <animated-number :value="25094"  :duration="1000" :formatValue="formatToPrice" class="grey--text text--darken-4" style="font-size: 70px;"/>                  
                  <div class="d-flex content_center">
                    <div style="font-size: 20px;">최근5년 실적합계</div>    
                  </div>                  
                </div>
              </div>
              <div style="position: relative; width : 0px; height: 0px; top:20px" >
                <div style="width: 150px; font-size: 14px; color: #000000a0;" >
                  (단위 : 년,백만원/ 정보통신)
                </div>                      
              </div>
            </v-col>
          </v-row>
        </div>   -->

      </div>        
    </div>

    <div class="mt-5" id="scoll_Service" align="center" justify="center" >            
      <div style="height: 30px;"></div>
      <div class="mt-15">
        <div class="title_caption">SERVICE</div>
        <div class="title_header">정보통신공사업</div>        
      </div>        
      <div class="d-flex justify-center flex-wrap" color="white" style="display: flex; align-items: center;">
        <div class="ma-3" style="">                            
          <img    
            class="img_outline"                          
            src="../assets/part1.png"           
            width="300px"/>        
        </div>
        <div class="ma-3" style="width : 400px">          
          <div class="content_caption text-left mt-4">
통신 선로설비 (광,동축,동,관로) 공사<br>
구내통신설비 공사<br>
교환기/전송설비 공사<br>
이동/위성/고정무선 통신설비 공사<br>
방송설비 공사<br>
정보제어/ 보안설비 공사<br>
정보망 /정보매체 설비 공사 <br>
철도통신/신호설비 공사<br>
자가망 설계, 공사<br>
스마트시티 정보통신 공사<br>
CCTV납품 시공<br><br>
          </div>
        </div>
      </div>

      <div class="mt-5">        
        <div class="title_header">전기공사업</div>        
      </div>        

      <div class="d-flex justify-center flex-wrap" color="white" style="display: flex; align-items: center;">
        <div class="ma-3" style="">                            
          <img    
            class="img_outline"                          
            src="../assets/part2.png"           
            width="300px"/>        
        </div>
        <div class="ma-3" style="width : 400px">          
          <div class="content_caption text-left mt-4">
발전설비공사<br>
송전설비공사/변전설비공사/배전설비공사<br>
산업시설물의 전기설비공사<br>
건축물의 전기설비공사<br>
구조물의 전기설비공사<br>
도로전기설비공사<br>
공항전기설비공사<br>
항만전기설비공사<br>
전기철도설비공사<br>
철도신호설비공사<br>
전기설비의 설치를 위한 공사<br><br>
          </div>
        </div>
      </div>
      
     
    </div>


    <div class="mt-5" id="scoll_Cert" align="center" justify="center" >            
      <div style="height: 30px;"></div>
      <div class="mt-15">
        <div class="title_caption">CERT</div>
      </div>        
      <div class="d-flex justify-center flex-wrap mt-3" color="white" style="display: flex; align-items: center;">
        <div class="ma-3" style="width: 300px;" >                          
          <img 
            class="img_outline"
            src="../assets/logo_ggd.svg"           
            width="150px"/>                            
        
        </div>
        <div class="ma-3" style="width : 400px">                            
          <div class="content_header text-left">
            공사업
          </div>
          <div class="content_caption text-left mt-4">
            <div class="d-flex">
              1997.02.24  <p style="font-weight: 800;" class="ml-3">정보통신공사업 (제110531호)</p>
            </div>
            <div class="d-flex">
              2008.01.30  <p style="font-weight: 800;" class="ml-3">전기공사업(경2-00369호)</p>
            </div>
          </div>
        </div>        
      </div>
      <div class="d-flex justify-center flex-wrap" color="white" style="display: flex; align-items: center;">
        <div class="ma-3" style="width: 300px;" >  
          <img             
            src="../assets/cer1.png"           
            width="100px"/>                        
          <img             
            src="../assets/cer2.png"           
            width="100px"/>                        
        </div>
        <div class="ma-3" style="width : 400px">                            
          <div class="content_header text-left">
            시스템인증
          </div>
          <div class="content_caption text-left mt-4">
            <div class="d-flex">
              2005.11.12  <p style="font-weight: 800;" class="ml-3">ISO9001 품질경영시스템(QK009320) </p>
            </div>
            <div class="d-flex">
              2011.10.18  <p style="font-weight: 800;" class="ml-3">ISO14001 환경경영시스템(EK005220) </p>
            </div>            
            <div class="d-flex">
              2020.11.13  <p style="font-weight: 800;" class="ml-3">ISO45001 안전보건경영시스템(OHK038620) </p>
            </div>            
          </div>
        </div>
      </div>

      <div class="d-flex justify-center flex-wrap" color="white" style="display: flex; align-items: center;">
        <div class="ma-3" style="width: 300px;" >                       
          <img 
            class="img_outline"
            src="../assets/logo_jungso.svg"           
            width="100px"/>                            
        
        </div>
        <div class="ma-3" style="width : 400px">                            
          <div class="content_header text-left">
            기타
          </div>
          <div class="content_caption text-left mt-4">
            <div class="d-flex">
              2014.10.01  <p style="font-weight: 800;" class="ml-3">경영혁신형 중소기업(R140602-02574호)</p>
            </div>
            <div class="d-flex">
              2014.03.06  <p style="font-weight: 800;" class="ml-3">직접생산확인(전산업무-소프트웨어개발) </p>
            </div>            
          </div>
        </div>
      </div>
    </div>


    <div class="mt-5" id="scoll_Contact" align="center" justify="center" >            
      <div style="height: 30px;"></div>
      <div class="mt-15">
        <div class="title_caption">CONTACT</div>
      </div>        
      <v-container fluid>
        <div
          class="flex"        
          flat        
          color="white"
        >
          <v-row
            class="ma-0"
            color="white"              
            align="center"              
            justify="center"                            
          >
            <div id="item-box3" class="ma-3 pa-6 black--text">        
              <div>Humantech Co., Ltd.<br></div>              
              <div>경기도 남양주시 덕송1로55번길 6, SG타워211호</div>                
              <h2 class="mt-0 mb-0">TEL : 031)878-8700</h2>
              <div class="mt-0 mb-0">FAX : 031)573-7341</div>
              <div class="mt-0 mb-0">EMail : human8700@naver.com</div>
            </div>
            <div id="item-box3"  class="ma-3 black--text">
              <img                    
                
                src="../assets/map.svg"           
                width="100%"          
                class="img_outline mt-3"
              />                    
            </div>                          
          </v-row>           
        </div>  
        </v-container>  
    </div>


  </div>
</template>


<script>
import axios from "axios"
import AnimatedNumber from "animated-number-vue";

export default {
  components: {  
    AnimatedNumber,    
  },
  data: () => ({ 
    dialog : false,
    renderForce : false,
    version : "",    
    iCircleWidth : 300,
    videoPath : null,    
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },

  },
  mounted () {                
    this.version = localStorage.getItem("Version");
    //console.log('current version :', this.version);  
    
    // 버전업이 되었으면 버전을 올리도록 처리  
    if (process.env.NODE_ENV === 'production') {  
      //console.log('version update check...');  
      //console.log(caches);  
      axios
        .get(`/version.json?timestamp=${new Date().getTime()}`)
        .then(res => {  
        //console.log('server version :', res.data.version);  
        if (res.data.version != this.version) {              
          if (this.version != "") {
            //this.deleteCash();                      
          } 
          localStorage.setItem("Version", res.data.version);                       
        }  
      });  
    }
  },
  methods: {
    formatToPrice(value) {
      return Number(value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    onResize () {
      const element = document.getElementById('CircleWidth');
      if (element) {
        this.iCircleWidth = element.offsetWidth * 0.85; // 너비 값을 높이로 설정
      }
    },
  }
}
</script>

<style scoped>
.content_get {    
  z-index: 2;
  width:100%;
  float:top;
  background: #FFFFFF;   
}
.content_why {    
  width:100%;  
  background: #2A2A2A;   
}


#content_about {    
  width:80%;    
  line-height:200%;
}

#line-height {
  line-height:200%;
}

#item-box {
  width:300px;
  line-height:200%;
}

#item-box2 {
  width:200px;
  line-height:200%;
}
#item-box3 {
  width:400px;
  line-height:200%;
}



#blinking2{
	-webkit-animation:blink2 10s ease-in-out infinite alternate;
    -moz-animation:blink2 10s ease-in-out infinite alternate;
    animation:blink2 10s ease-in-out infinite alternate;
}
@-webkit-keyframes blink2{
    0% {opacity:1;}
    15% {opacity:0.2;}
    100% {opacity:0;}
}
@-moz-keyframes blink2{
    0% {opacity:1;}
    15% {opacity:0.2;}
    100% {opacity:0;}
}
@keyframes blink2{
    0% {opacity:1;}
    15% {opacity:0.2;}
    100% {opacity:0;}
} 


  .magnifyBorder {
    width:240px;
    line-height:200%;
    background: #FFFFFFAA;

    padding: 18px 36px;
    border-radius: 5px;
    box-shadow: inset 0 0 0 0.2px #222222;;
    transition: 300ms box-shadow cubic-bezier(0.4, 0, 0.6, 1), 300ms background-color cubic-bezier(0.4, 0, 0.6, 1), 300ms color cubic-bezier(0.4, 0, 0.6, 1);;
    color: #222222;
  }

  .magnifyBorder:hover {
      background: #FFFFFFE0;
      box-shadow: inset 0 0 0 4px #D6433B;
      color : #222222;
  }


  .class_play_btn{
    z-index: 200;
    position: relative;
    top : 70px;
    height : 0px;
  }

  .video_x_button{
    z-index: 100;
    position: absolute;    
    top : 0px;
    left : 0px;
    height: 25px;
    width : 100%;
    background: #00000000;   
  }

  .circle{
    border-radius: 50%;
    background: #00000010;   
    height : 200px;
    width : 200px;
    text-align: center;
    line-height: 200px;    
    font-size: 40px;
    
  }



#blinking1{
	-webkit-animation:blink 5s ease-in-out infinite alternate;
    -moz-animation:blink 5s ease-in-out infinite alternate;
    animation:blink 5s ease-in-out infinite alternate;
}
@-webkit-keyframes blink{
    0% {opacity:0.3;}
    100% {opacity:1;}
}
@-moz-keyframes blink{
    0% {opacity:0.3;}
    100% {opacity:1;}
}
@keyframes blink{
    0% {opacity:0.3;}
    100% {opacity:1;}
} 


  .title_caption {
    color:#00000030;
    font-weight: 400;
    font-size: 30px;
  }
  .title_header {
    color:#000000C0;
    font-weight: 700;
    font-size: 40px;
  }
  .title_sub {
    color:#000000C0;
    font-weight: 500;
    font-size: 25px;
  }
  .content_header {
    color:#000000C0;
    font-weight: 500;
    font-size: 23px;
  }
  .content_caption {
    color:#000000A0;
    font-weight: 300;
    font-size: 15px;
  }

  .image_title {    
    background: #00000010;
    height : 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    border-radius: 5px;  
  }

  .img_outline {
    border-radius: 10px; /* 모서리 둥글게 */
    object-fit: cover;
  }
  .content_center {
    text-align:center;
    display:flex; 
    align-items:center;
    justify-content:center;    
  }


</style>





<!--  -->